.ConfigVeiculo_BotaoAdd_qergerwg {
    color: #fff;
    background: #3bb037 !important;
    border-color: #3bb037 !important;
    background-color: #3bb037 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.ConfigVeiculo_BotaoAdd_qergerwg:hover {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.ConfigVeiculo_BotaoAdd_qergerwg:active {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}
.ConfigVeiculo_BotaoAdd_qergerwg:focus {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.ConfigVeiculo_BotaoEditar_ffervregbrtyt {
    color: #fff !important;
    background: #e08e0b !important;
    border-color: #e08e0b !important;
    background-color: #e08e0b !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.ConfigVeiculo_BotaoEditar_ffervregbrtyt:hover {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}

.ConfigVeiculo_BotaoEditar_ffervregbrtyt:active {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}
.ConfigVeiculo_BotaoEditar_ffervregbrtyt:focus {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}

.fotoFundoPrimeiroAcesso {
    width: 20%;
    height: 100vh;
    /* object-fit: contain; */
    /* height: 100vh; */
    /* background: url('./../../assets/imagens/login.png') 25% 40%;
    background-size: cover; */
}

@media only screen and (max-width: 576px) {
    .fotoFundoPrimeiroAcesso {
        display: none;
    }
}

.Empresas_BotaoAdd_agfjlkabofd {
    color: #fff;
    background: #3bb037 !important;
    border-color: #3bb037 !important;
    background-color: #3bb037 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.Empresas_BotaoAdd_agfjlkabofd:hover {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.Empresas_BotaoAdd_agfjlkabofd:active {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}
.Empresas_BotaoAdd_agfjlkabofd:focus {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.ant-tabs-card.ant-tabs-top.tab-municipios>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top.tab-municipios>div>.ant-tabs-nav .ant-tabs-tab-active {
    border-style: solid;
    border-bottom-color: transparent !important;
    border-bottom-width: 0px !important;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #1890ff;
}

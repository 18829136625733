.RelatorioVeiculosPriprios_BotaoXLSX_dkhjfvbwefewlfv {
    color: #fff;
    background: #3bb037 !important;
    border-color: #3bb037 !important;
    background-color: #3bb037 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.RelatorioVeiculosPriprios_BotaoXLSX_dkhjfvbwefewlfv:hover {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.RelatorioVeiculosPriprios_BotaoXLSX_dkhjfvbwefewlfv:active {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}
.RelatorioVeiculosPriprios_BotaoXLSX_dkhjfvbwefewlfv:focus {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

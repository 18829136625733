
.AddEventoEConvidado_BotaoEditar_dekchjcvkjhvdc {
    color: #fff !important;
    background: #e08e0b !important;
    border-color: #e08e0b !important;
    background-color: #e08e0b !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.AddEventoEConvidado_BotaoEditar_dekchjcvkjhvdc:hover {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}

.AddEventoEConvidado_BotaoEditar_dekchjcvkjhvdc:active {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}
.AddEventoEConvidado_BotaoEditar_dekchjcvkjhvdc:focus {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}

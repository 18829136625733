.marginTopTablesListUsuarios
.ant-table.ant-table-small
.ant-table-tbody>tr>td {
    padding: 0;
}
.table-row-gestor {
    background-color: #00a2ff25;
}

.ListUsuarios_BotaoAdd_gvorehbgo {
    color: #fff;
    background: #3bb037 !important;
    border-color: #3bb037 !important;
    background-color: #3bb037 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.ListUsuarios_BotaoAdd_gvorehbgo:hover {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.ListUsuarios_BotaoAdd_gvorehbgo:active {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}
.ListUsuarios_BotaoAdd_gvorehbgo:focus {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.ListUsuarios_BotaoEditar_ewfubwpoeg {
    color: #fff !important;
    background: #e08e0b !important;
    border-color: #e08e0b !important;
    background-color: #e08e0b !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    /* width: 90px !important; */
}

.ListUsuarios_BotaoEditar_ewfubwpoeg:hover {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}

.ListUsuarios_BotaoEditar_ewfubwpoeg:active {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}
.ListUsuarios_BotaoEditar_ewfubwpoeg:focus {
    background: #F99E0E !important;
    background-color: #F99E0E !important;
    border-color: #F99E0E !important;
}
